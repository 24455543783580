@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: "Inter",sans-serif;
}

.icons{
    font-size: 20px;
    margin-right: 10px;
}

#globeicon{
    font-size: 20px;
}

.dropmenuitems{
    --bs-dropdown-link-active-bg: rgba(179, 215, 249, 0.541);
}

#basic-navbar-nav{
    gap: 1rem;
}

.bg-body-tertiary{
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

#rlinks{
    text-decoration: none;
    color: #444444;
}

#himage{
    margin-bottom: 0px;
    border-radius: 5px;

}

#llinks{
    text-decoration: none;
    color: #444444;
}

#llinks:hover{
    color: #6c8bf3;
}


#rlinks:hover{
    color: #6c8bf3;
}


