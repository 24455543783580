@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.mainabout {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.titlesabout {
  width: 100%;
  max-width: 800px; 
}

.titlesabout h1 {
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.titlesabout p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}



@media only screen and (max-width: 768px) {
  .titlesabout {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .titlesabout{
    width: 70vw;
    font-size: 10px;
  }
}
