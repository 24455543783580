@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

#titlescontainersound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

#titlessound {
    padding: 22px;
    text-align: center;
}

#titlessound h1 {
    font-size: 30px;
}

#titlessound h2 {
    font-size: 18px;
}

#buttonscontainer {
    padding: 30px;
    display: flex;
    justify-content: center;
}

#buttons {
    width: 100%;
    max-width: 600px; 
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    justify-content: center;
}

#buttons button {
    display: flex;
    flex-direction: column;
    background-color: #056CF2;
    color: white;
    border: none;
    height: 3.3rem;
    width: 6.3rem;
    outline: none;
    padding: 1px 12px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: transform 0.2s ease-out;
}

#buttons button:active {
    transform: scale(1.2);
}

#ilclass{
    transform: scaleX(-1);
}

#buttons button span {
    font-size: 11px;
    margin-top: 5px;
}

#hw {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.howto {
    width: 90%;
    max-width: 600px;
    padding: 10px;
}

.howto h3 {
    font-weight: bold;
    margin-bottom: 15px;
}

.howto ol {
    line-height: 2rem;
}

.faqsound {
    display: flex;
    justify-content: center;
}

#fnqsound {
    width: 70%;
    max-width: 800px;
    padding: 10px;
}

#fnqsound h4 {
    margin-bottom: 20px;
}

#fnqsound p {
    margin-bottom: 3rem;
    line-height: 2rem;
}


@media (max-width: 768px) {
    #buttons {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 500px) {
    #titlessound h1 {
        font-size: 24px;
    }

    #titlessound h3 {
        font-size: 16px;
    }

    #buttons button {
        width: 100%;
    }

    #buttons{
        gap: 20px;
        width: 80%;
    }

    #fnqsound h4{
        font-size: 18px;
    }

    #fnqsound p{
    font-size: 14px;
    }

    .howto h3 {
        font-size: 25px;
    }
    
    .howto ol {
        line-height: 2rem;
    }

}
