@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
  font-family: "Inter",sans-serif;
}

#mainprivacy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

#titlesprivacy {
    width: 60%;
    display: flex;
    flex-direction: column;
}

#titlesprivacy h1,
#titlesprivacy h2,
#titlesprivacy p {
    text-align: left;
}

#titlesprivacy h1 {
    font-weight: bold;
}

#titlesprivacy h2 {
    font-weight: bold;
    color: black;
}



@media only screen and (max-width: 768px) {
    .titlesprivacy {
      width: 90%;
    }
    
  }
  
  @media only screen and (max-width: 480px) {
    #titlesprivacy{
        width: 80%;
        font-size: 16px;
      }

      #titlesprivacy h2{
        font-size: 19px;
      }
      
  }