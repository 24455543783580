@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: 'Inter',sans-serif;
    box-sizing: border-box;
}

#footer{
    display: flex;
    align-items: center;
    height: 20vh;
    justify-content: center;
    flex-direction: column;
    background-color: #404040;
}

#three, #four, #five, #six{
    display: none;
}

#one ul, #two ul{
    display: flex;
}
#one ul span, #two ul span{
    margin: 0 10px;
    color: white;
}

#rslinks{
    color: white;
    text-decoration: none;
}

#rslinks:hover{
    color: #6c8bf3;
}

@media (max-width: 500px){
    #one, #two{
        display: none;
    }


    #three, #four, #five, #six{
        display: flex;
        color: white;
        font-size: 20px;
    }

    #three, #four, #five, #six ul{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        text-align: center;
    }

    #three ul span, #four ul span, #five ul span{
        margin: 0 5px;
    }

    #footer{
        height: 40vh;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        justify-content: center;
    }

    #foodiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}