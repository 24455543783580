@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: "Inter",sans-serif;
}

#cdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

#cdiv h1{
    font-weight: bold;
}


#sp{
    text-decoration: underline;
}

#sp:hover{
    color: #3B6FD9;
    cursor: pointer;
}