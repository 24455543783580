@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.headingsarea {
    padding: 20px;
}

.rightone {
    background-color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(25, 10, 236, 0.196);
    height: 30vh;
    width: 45vh;
    border-radius: 5px;
    padding: 5px;
    max-width: 100%;
}

#errorpara {
    padding: 10px;
}

#failedaccess {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#failedaccess #sicon {
    font-size: 3rem;
    color: rgba(221, 90, 90, 0.703);
}

#failedaccess h6 {
    font-weight: bold;
}

#titlesmic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    border: none;
}

.icons {
    font-size: 30px;
}

#titlesmic h1 {
    font-size: 30px;
    font-weight: bold;
}

#titlesmic h2 {
    font-size: 21px;
    color: #444444;
}

#left {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

#ulleft {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

#left h2 {
    font-size: 22px;
    font-weight: bold;
}

.testmic {
    font-size: 35px;
    color: #82a8d9;
    transition: 0.2s all;
    margin: auto;
}

.testmic:active {
    transform: scale(0.98);
}

.micarea {
    padding: 10px;
}

.mictester {
    display: flex;
    padding: 3rem 30px;
    align-items: center;
    justify-content: space-around;
    background-color: #d8def2;
    border-radius: 25px;
    margin-bottom: 4rem;
}

#firststep,
#secondstep,
#thirdstep {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#firststep h6 {
    font-weight: bold;
    font-size: medium;
    margin-top: 10px;
}

#firststep button,
#secondstep button {
    border-radius: 50%;
    height: 4.4rem;
    width: 4.4rem;
    background-color: #444444;
    border: none;
    margin-top: 2rem;
}

#firststep #micicon,
#secondstep #stopicon {
    font-size: 2rem;
    color: rgb(255, 58, 58);
}

#thirdstep{
    padding-top: 25px;
}

#thirdstep #bdiv {
    display: flex;
    gap: 1rem;
}

#thirdstep audio {
    width: 290px;
}

#bdiv button {
    border: none;
    padding: 6px 8px;
    margin-top: 10px;
    color: #3b6fd9;
    border-radius: 10px;
    background-color: #fff;
    transition: 0.3s;
}

#bdiv button:hover {
    border: none;
    background-color: #3b6fd9;
    color: white;
}

.faqmic {
    display: flex;
    justify-content: center;
}

#fnqmic {
    width: 50vw;
    margin-top: 5rem;
    padding: 10px;
}

#fnqmic h4 {
    margin-bottom: 20px;
}

#fnqmic p {
    margin-bottom: 5rem;
    line-height: 2rem;
}


@media (max-width: 500px) {
    .mictester {
        flex-direction: column;
    }

    .rightone {
        width: 70vw;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #firststep h6{
        font-size: medium;
    }

    #thirdstep{
        gap: 20px;
    }

    #urldiv audio{
        width: 60vw;
    }

    #fnqmic{
        width: 80vw;
    }

    #titlesmic h2{
        text-align: center;
    }

}


@media (min-width: 501px) and (max-width: 1024px) {
    .mictester {
        padding: 2rem;
    }

    #fnqmic {
        width: 70vw;
    }
}


@media (min-width: 1025px) {
    .headingsarea,
    .micarea,
    .faqmic {
        padding: 4rem;
    }

    .mictester {
        padding: 4rem;
    }

    #fnqmic {
        width: 50vw;
    }
}
