@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: 'Inter',sans-serif;
    
    box-sizing: border-box;
}

#alpha{
    display: flex;
    justify-content: center;
    align-items: center;
}

.titles{
    text-align: center;
    background: transparent;
    padding: 3.5rem ;
}

.titles h1{
    font-family: 'Inter semibold 600',sans-serif;
    font-size: 30px;
}
.titles h2{
    font-size: 18px;
    color: #444444;
    text-align: center;
}

.toollist{
display: flex;
justify-content: center;
flex-direction: row;
gap: 2rem;
padding: 4px 0;
}

.toollist li{
    display: block;
    text-align: center;
    font-weight: bold;
    background-color: #fff;
}

.toollist li #rlinks{
    padding: 25px 25px;
    background: transparent;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
    border: 1px solid #ced4da;
    box-shadow: 0px 23px 4px -21px rgba(0,0,0,0.1);
    box-sizing: border-box;
    outline: none;
}

.toollist li #rlinks:hover{
    border: 2px solid #6c8bf3 ;
    padding: 24px 24px;
}

.toollist li .icons{
    font-size: 50px;
    color: black;
}
.toollist a:link{
    text-decoration: none;
}

.toollist a{
    color: black;
}

#listdivs{
    width: 100%;
    height: 100%;
}

.fdiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

#features{
    margin-top: 10rem;
    display: grid;
    padding: 2rem;
    display: grid; 
    width: 60vw;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 3.5rem 3.8rem;
}

#indiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

#indiv p{
    font-size: 16px;
    line-height: 20px;
    word-spacing: 5px;
    
}

#indiv h6{
    margin-bottom: 2rem;
    font-weight: bold;
}

#hicons{
    font-size: 2.5rem;
    margin-bottom: 5px;
}





@media (max-width: 500px){

    .toollist{
        flex-direction: column;
    }

    .flexdiv{
        padding: 10px;
    }

    #features{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 70vw;
    }

}

