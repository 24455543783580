@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    box-sizing: border-box;
    font-family: "Inter",sans-serif;
}

#mainarea {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

#tonearea {
    padding: 1rem;
    border-radius: 5px;
    background-color: #D8DEF2;
    width: 100%;
    max-width: 600px; 
}

#headi{
    display: flex;
    align-items: center;
    justify-content: center;
}

#hhdiv{
    margin-top: 3rem;
    width: 65%;
}

#tmaintone{
    display: flex;
    align-items: center;
    justify-content: center;
}

#titlestone{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding: 2rem;
}

#titlestone h1{
    font-size: 35px;
    font-weight: bold;
}

#titlestone p{
    margin-top: 2rem;
    font-size: 19px;
}

#hdiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

#howto{
    margin-top: 5rem;
    width: 50%;
    line-height: 35px;
}

#howto h3{
    font-weight: bold;
}


#frdiv {
    width: 100%; 
}

.labels{
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
}

#insidefreq {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;   
}

#fnumbers{
    padding:0 4px;
}

#hzz {
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 5.3rem;
    background-color: #fff;
}

#hz {
    padding: 5px 4px;
    background-color: #fff;
    border-radius: 5px;
}

#frange {
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    width: 75%;
    position: relative;
    margin-left: 4rem;
}


.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    margin-top: 1rem;
}


#p1,
#p2 {
    padding: 5px 17px;
    font-size: 1.4rem;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

#p1{
    background-color:#069f57 ;
}

#p1:hover {
    background-color: #04d976;
}

#p2 {
    background-color: #960814;
}

#p2:hover {
    background-color: #d5313f;
}

.waveforms {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 5px;
    padding: 5px;
    margin-top: 0.5rem;
    font-size: large;
    border-radius: 5px;
    background-color: #A7B3D9;
}

.waveform {
    margin-top: 10px;   
}


.volume{
    margin-top: 2rem;
}

#voldiv {
    width: 100%; 
}

#insidevol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;  
}

#volumenumber {
    padding: 5px 12px;
    border-radius: 5px;
    margin-right: 0.5rem;
    justify-content: center;
    display: flex;
    width: 2.1rem;
    background-color: #F2F2F2;
}

#vrange {
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    width: 55%;
    position: relative;
    margin-left: 40%;
}


#bal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    padding: 10px 0;
}

#bal label{
    margin-bottom: 10px;
}

#balancediv {
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
}

#brange {
    appearance: none;
    background-color: transparent;
    cursor: pointer;
}


input[type="range"]::-webkit-slider-runnable-track {
    background: #b6ad90;
    height: 1.1rem;
    border-radius: 15px;
}

input[type="range"]::-moz-range-track {
    background: #b6ad90;
    height: 1.1rem;
    border-radius: 15px;
}

input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    height: 1.4rem;
    width: 1rem;
    margin-top: -2.4px;
    border-radius: 2px;
    background-color: #fff8e8;
    border: 1px solid #656F8C ;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
}

input[type="range"]::-moz-range-thumb {
    border: none;
    border-radius: 0;
    height: 1.4rem;
    width: 1rem;
    background-color: #fff8e8;
    border-radius: 2px;
    border: 1px solid #656F8C ;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
    outline: 3px solid #3b70d991;
}

input[type="range"]:focus::-moz-range-thumb {
    outline: 3px solid #3b70d991;
}

.faqtone{
    display: flex;
    justify-content: center;
}

#fnqtone{
    width: 50vw;
    padding: 10px;
    margin-top: 4rem;
}
#fnqtone h4{
    margin-bottom: 20px;
}

#fnqtone p{
    margin-bottom: 5rem;
    line-height: 2rem;
}



@media screen and (max-width: 700px) {
   
    #insidefreq {
        justify-content: center; 
        padding: 10px 0; 
        margin-left: 0; 
    }

    #frange {
        width: 92%; 
        margin-left: 0; 
    }

 
    #insidevol {
        justify-content: center; 
        padding: 10px 0; 
        margin-left: 0; 
    }

    #vrange {
        width: 92%; 
        margin-left: 0; 
    }

  
    #brange {
        width: 100%; 
    }

    
    #frdiv,
    #voldiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #fnumbers,
    #volumenumber {
        padding: 0 5px; 
    }

    
    #p1, #p2 {
        font-size: 0.9rem; 
    }

    #titlestone{
        width:100%
    }

    #titlestone h1{
        font-size: 25px;
    }

    #titlestone p{
        font-size: 16px;
    }

    #hhdiv{
        width: 80vw;
    }

    #howto{
        width: 80vw;
    }

    #howto h3{
        font-size: 20px;
    }

    #howto ol{
        font-size: 14px;
    }

    #fnqtone{
        width: 80vw;
    }

    #hhdiv h5{
        font-size: 17px;
    }

    .volume .labels{
        margin-right: -1px;
    }
}

